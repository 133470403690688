import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import CancelRounded from '@material-ui/icons/CancelRounded';
import { Link } from 'react-router-dom';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import { Box, Chip } from '@mui/material';
import { Colors, UserStatus } from '../../../../enums/enums';
import { fetchCurrentUsers, getUsers } from '../../../../redux/actions/users';
import { format, parseISO } from 'date-fns';
import EditIcon from '@mui/icons-material/Edit';
import { useState } from 'react';
import EditUserDialog from '../../users/EditUserDialog';

interface Props {
  clientId: string;
}
export default function ClientUsersList({ clientId }: Props) {
  const dispatch = useDispatch();
  const users = useSelector(getUsers);
  const usersFilteredByClient = users.filter((user) => user.clientId === clientId);
  const [openEditUserDialog, setOpenEditUserDialog] = useState(false);
  const [user, setUser] = useState(null);

  React.useEffect(() => {
    dispatch(fetchCurrentUsers());
  }, [dispatch]);

  const columns = [
    {
      name: 'id',
      options: {
        display: false
      }
    },
    {
      name: 'title',
      label: 'Title',
      options: {
        display: false
      }
    },
    {
      name: 'company',
      label: 'Company',
      options: {
        display: false
      }
    },
    {
      name: 'experience',
      label: 'Experience',
      options: {
        display: false
      }
    },
    {
      name: 'email2',
      label: 'Alternate Email',
      options: {
        display: false
      }
    },
    {
      name: 'linkedin',
      label: 'LinkedIn',
      options: {
        display: false
      }
    },
    {
      name: 'country',
      label: 'Country',
      options: {
        display: false
      }
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        sort: true,
        customBodyRender: (v, x) => (
          <ListItem>
            <ListItemAvatar>
              <Avatar
                sx={{ bgcolor: x.rowData[9] === UserStatus.Active ? Colors.TextElevated : Colors.Inactive, ml: -2 }}
              >
                {v.charAt(0)}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography sx={{ color: Colors.TextElevated }}>
                    <b>{v}</b>
                    <span style={{ fontSize: 'small' }}>
                      {x.rowData[15] && x.rowData[15] !== 'Prefer not to say' ? ', (age: ' + x.rowData[15] + ')' : ''}
                    </span>
                  </Typography>
                </React.Fragment>
              }
              secondary={
                <React.Fragment>
                  <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                    {x.rowData[1]} at {x.rowData[2]}
                  </Typography>
                  <Typography variant="body2">Experience: {x.rowData[3]} years</Typography>
                  {x?.rowData[5] !== 'N/A' && x?.rowData[5]?.includes('linkedin.com') ? (
                    <Box sx={{ maxWidth: '400px', width: '330px', wordWrap: 'break-word' }}>
                      <a href={v} target="_blank" rel="noreferrer nofollow">
                        {x?.rowData[5]}
                      </a>
                    </Box>
                  ) : (
                    <Typography fontSize="small" sx={{ maxWidth: '400px', width: '330px', wordWrap: 'break-word' }}>
                      {v}
                    </Typography>
                  )}
                </React.Fragment>
              }
            />
          </ListItem>
        )
      }
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        customBodyRender: (v, x) => (
          <>
            <Typography>{v}</Typography>
            {x.rowData[4] && <Typography fontSize="small">Alternate email: {x.rowData[4]}</Typography>}
          </>
        )
      }
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        //   empty: true,
        customBodyRender: (v, x) => (
          <Chip
            style={{ color: 'black', backgroundColor: v === UserStatus.Active ? Colors.Active : Colors.Inactive }}
            label={v}
          />
        )
      }
    },
    {
      name: 'note',
      label: 'Note',
      options: {
        customBodyRender: (v, x) => (
          <Typography fontSize="small" sx={{ width: '150px' }}>
            {v}
          </Typography>
        )
      }
    },
    {
      name: 'createdAt',
      label: 'Onboard',
      options: {
        customBodyRender: (v, x) => <Typography>{format(parseISO(v), 'MMM dd, yyyy')}</Typography>
      }
    },
    {
      name: 'city',
      label: 'Location',
      options: {
        customBodyRender: (v, x) => {
          return (
            <>
              <Typography fontSize="small">{v},</Typography>
              <Typography fontSize="small">{x?.rowData[6]}</Typography>
            </>
          );
        }
      }
    },
    {
      name: 'timezone',
      label: 'Timezone',
      options: {
        customBodyRender: (v, x) => {
          return (
            <>
              <Typography fontSize="small">{v}</Typography>
            </>
          );
        }
      }
    },
    {
      name: 'authId',
      label: 'Active Portal? ',
      options: {
        //   empty: true,
        customBodyRender: (v, x) => {
          return v ? (
            <Typography style={{ color: 'green' }}>
              <CheckCircleRoundedIcon />
            </Typography>
          ) : (
            <Typography style={{ color: 'darksalmon' }}>
              <CancelRounded />
            </Typography>
          );
        }
      }
    },
    {
      name: 'age',
      label: 'Age',
      options: {
        display: false
      }
    },
    {
      name: 'id',
      label: 'View Profile',
      options: {
        customBodyRender: (v) => (
          <Link to={`/users/${v}`}>
            <SearchRoundedIcon style={{ fontSize: '1.8rem', color: Colors.ButtonGreen }} />
          </Link>
        )
      }
    },
    {
      name: 'id',
      label: 'Edit User',
      options: {
        customBodyRender: (v) => (
          <EditIcon
            sx={{ cursor: 'pointer', fontSize: '1.8rem', color: Colors.MenuText }}
            onClick={() => {
              const foundUser = usersFilteredByClient?.find((user) => user.id === v);
              console.log('User >>>>', user);
              setUser(foundUser);
              setOpenEditUserDialog(true);
            }}
          />
        )
      }
    }
  ];

  const options = {
    filter: true,
    filterType: 'dropdown',
    selectableRows: 'none'
  };

  return (
    <div>
      <MUIDataTable title={''} data={usersFilteredByClient} columns={columns} options={options} />
      <EditUserDialog open={openEditUserDialog} user={user} onClose={() => setOpenEditUserDialog(false)} />
    </div>
  );
}