import { combineReducers, Reducer } from 'redux';
import { ClientsReducer } from './clients';
import { SurveysReducer } from './surveys';
import { SurveysResponseReducer } from './surveysResponse';
import { UsersReducer } from './users';
import { GroupsReducer } from './groups';
import { CoachingSessions } from './coachingSessions';
import { ContentReducer } from './content';
import { TasksReducer } from './tasks';

export const allReducers: Reducer = combineReducers({
  coachingSessions: CoachingSessions,
  clients: ClientsReducer,
  surveys: SurveysReducer,
  surveysResponse: SurveysResponseReducer,
  users: UsersReducer,
  groups: GroupsReducer,
  content: ContentReducer,
  tasks: TasksReducer
});