import api from '../../services/api';
import { toast } from 'react-toastify';
import {
  ICoachingSession,
  ICoachingSessionBulk
} from '../../components/admin/mentorship/coaching-sessions/coaching.models';

export enum CoachingSessionActionTypes {
  CREATE = 'COACHING_SESSION_CREATE',
  FETCH = 'COACHING_SESSION_FETCH',
  FETCH_USER_SESSIONS = 'USER_SESSION_FETCH',
  FETCH_CLIENT_SESSIONS = 'CLIENT_SESSION_FETCH',
  UPDATE = 'COACHING_SESSION_UPDATE',
  DELETE = 'COACHING_SESSION_DELETE'
}

const baseUrl = '/coaching-sessions';

// Create single group session
export function createCoachingSession(session: ICoachingSession) {
  return function (dispatch) {
    const url = baseUrl;
    return api
      .post(url, session)
      .then(({ data }) => {
        toast.success('Successfully created session');
        dispatch({
          type: CoachingSessionActionTypes.CREATE,
          payload: data
        });
      })
      .catch((error) => createSessionErrorHandler(error));
  };
}

// Create bulk group sessions
export function createBulkCoachingSession(session: ICoachingSessionBulk) {
  console.log('Coaching session: ', session);

  return function (dispatch) {
    const url = `${baseUrl}/bulk`;
    return api
      .post(url, session)
      .then(() => {
        // do nothing as the Caller will re-fetch sessions
        toast.success('Successfully created sessions');
      })
      .catch((error) => createSessionErrorHandler(error));
  };
}

// Create single individual session
export function createIndividualSession(session: ICoachingSession) {
  return function (dispatch) {
    const url = `${baseUrl}/individual`;
    return api
      .post(url, session)
      .then(({ data }) => {
        toast.success('Successfully created individual session');
        dispatch({
          type: CoachingSessionActionTypes.CREATE,
          payload: data
        });
      })
      .catch((error) => createSessionErrorHandler(error));
  };
}

export function fetchAllCoachingSessions() {
  return function (dispatch) {
    const url = baseUrl;
    return api.get(url).then(({ data }) => {
      dispatch({
        type: CoachingSessionActionTypes.FETCH,
        payload: data
      });
    });
  };
}

export function fetchUserSessionsById(userId: string) {
  return function (dispatch) {
    const url = `${baseUrl}/${userId}`;
    return api.get(url).then(({ data }) => {
      dispatch({
        type: CoachingSessionActionTypes.FETCH_USER_SESSIONS,
        payload: data
      });
    });
  };
}

export function fetchAllClientSessionsById(clientId: string) {
  return function (dispatch) {
    const url = `${baseUrl}/client-sessions/${clientId}`;
    return api.get(url).then(({ data }) => {
      dispatch({
        type: CoachingSessionActionTypes.FETCH_CLIENT_SESSIONS,
        payload: data
      });
    });
  };
}

export function updateCoachingSession(payload) {
  return function (dispatch) {
    const url = baseUrl;
    return api
      .patch(url, payload)
      .then(({ data }) => {
        dispatch({
          type: CoachingSessionActionTypes.UPDATE,
          payload: data
        });
        toast.success('Successfully updated session');
      })
      .catch((error) => {
        console.error('Error updating session', error);
        toast.error(
          `Error updating session.\nMessage: ${error.response?.data?.message || error.request || error.message}`
        );
      });
  };
}

export function deleteCoachingSession(sessionId: string) {
  return function (dispatch) {
    const url = `${baseUrl}/${sessionId}`;
    return api
      .delete(url)
      .then((deleteResponse) => {
        const { data, statusText } = deleteResponse;
        const deletedFromGoogle = data?.deleteResponseGoogle;
        const deleteResultDb = data?.deleteResultDb;
        const googleEventExists = data?.googleEventExists;

        if (deleteResultDb.affected === 1) {
          dispatch({
            type: CoachingSessionActionTypes.DELETE,
            payload: sessionId
          });
          const toastMessageGoogle = deletedFromGoogle ? ' and from Google Calendar' : ' but NOT from Google Calendar';
          const messageToAdd = googleEventExists ? toastMessageGoogle : '';
          toast.success('Successfully deleted session from database' + messageToAdd);
        } else {
          toast.error(`Error deleting session.\nMessage: ${statusText}`);
        }
      })
      .catch((error) => {
        console.error('Error deleting session', error);
        toast.error(
          `Error deleting session.\nMessage: ${error.response?.data?.message || error.request || error.message}`
        );
      });
  };
}

const createSessionErrorHandler = (error) => {
  console.log('Error creating coaching session(s): ', error);
  throw error;
};

// selectors
export const getCoachingSessions = (state) => state.coachingSessions.coachingSessions;
export const getUserSessions = (state) => state.coachingSessions.userSessions;
export const getAllClientSessions = (state) => state.coachingSessions.clientSessions;