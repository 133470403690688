import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import {
  Button,
  Stack,
  DialogContent,
  Typography,
  CardMedia,
  Card,
  CardContent,
  Select,
  InputLabel,
  Box,
  FormControl
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { Dialog, DialogActions, DialogTitle, FormLabel, MenuItem } from '@material-ui/core';
import { Colors } from '../../../../enums/enums';
import { FileObjectStatus } from '../config.enum';
import { InputFileUpload } from '../../../shared/input-file-upload/InputFileUpload';
import api from '../../../../services/api';
import { fetchFacilitatorsProfiles, saveFacilitator } from '../../../../redux/actions/content';
import { toast } from 'react-toastify';
import { IUser } from '../../../../interfaces/interfaces';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { noPhotoUrl } from '../../../../constants/constants';

interface Props {
  open: boolean;
  fileObject: {
    id: string;
    filename: string;
    status: FileObjectStatus;
    title: string;
    azureUrl: string;
    thumbnailName: string;
  };
  users: IUser[];
  onClose: Dispatch<SetStateAction<boolean>>;
}

interface FileUploadResponse {
  filename: string;
  filepath: string;
  azureUrl: string;
}

const EditFacilitator = (props: Props) => {
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const { open, fileObject, users, onClose } = props;
  const [title, setTitle] = React.useState('');
  const [status, setStatus] = React.useState(fileObject?.status);
  const [userId, setUserId] = React.useState(fileObject?.thumbnailName);
  const [azureUrl, setAzureUrl] = React.useState(fileObject?.azureUrl);

  const defaultUser = users.find((user) => user.id === fileObject?.thumbnailName);

  const { handleSubmit, control, reset, setValue } = useForm({
    defaultValues: {
      id: fileObject?.id,
      title: fileObject?.title,
      status: fileObject?.status,
      azureUrl: fileObject?.azureUrl,
      userId: fileObject?.thumbnailName
    }
  });

  useEffect(() => {
    setStatus(fileObject?.status);
    setTitle(fileObject?.title);
    setUserId(fileObject?.thumbnailName);
    setAzureUrl(fileObject?.azureUrl);
    reset({
      id: fileObject?.id,
      title: fileObject?.title,
      status: fileObject?.status,
      azureUrl: fileObject?.azureUrl,
      userId: fileObject?.thumbnailName
    });
  }, [fileObject, reset]);

  const onSubmit = () => {
    try {
      dispatch(saveFacilitator(fileObject?.id, title, status, userId));
      handleClose();
    } catch (e) {
      console.error('Error updating Facilitator Profile: ', e);
    }
  };

  const handleSelectedFile = (files: FileList): void => {
    if (files?.length) {
      const file = files.item(0);
      console.log('File selected', file);

      const formData = new FormData();
      formData.append('file', file);

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
      api
        .post<FileUploadResponse>(`/content/facilitator-photo/${fileObject?.id}`, formData, config)
        .then(({ data }) => {
          dispatch(fetchFacilitatorsProfiles());
          setAzureUrl(data?.azureUrl);
          console.log('API response on Photo upload: ', data);
          toast.success('Successfully uploaded Facilitator photo');
        })
        .catch((error) => {
          console.error('Error uploading Facilitator photo', error);
          toast.error(
            `Error uploading Facilitator photo.\nMessage: ${
              error.response?.data?.message || error.request || error.message
            }`
          );
        });
    }
  };

  const deleteProfilePhoto = () => {
    const filename = fileObject?.filename;
    return api
      .delete(`/content/facilitator-photo/${fileObject?.id}`, {
        data: {
          filename
        }
      })
      .then((deleteResponse) => {
        console.log('API response on photo delete: ', deleteResponse);
        toast.success('Successfully deleted Facilitator photo');
        setAzureUrl('');
      })
      .catch((error) => {
        console.error('Error deleting Facilitator photo', error);
        toast.error(
          `Error deleting Facilitator photo.\nMessage: ${
            error.response?.data?.message || error.request || error.message
          }`
        );
      });
  };

  const handleClose = () => {
    onClose(!open);
    dispatch(fetchFacilitatorsProfiles());
    reset();
  };

  const insertBreak = () => {
    if (inputRef.current) {
      const start = inputRef.current.selectionStart || 0;
      const end = inputRef.current.selectionEnd || 0;

      // Insert two `<br>` tags at the cursor position
      const updatedText = title.slice(0, start) + '<br><br>' + title.slice(end);

      setTitle(updatedText);

      // Reset cursor position after both `<br>` tags
      setTimeout(() => {
        inputRef.current!.selectionStart = start + 9; // Move cursor after `<br><br>`
        inputRef.current!.selectionEnd = start + 9;
        inputRef.current!.focus();
      }, 0);
    }
  };

  return (
    <Dialog disableEnforceFocus open={open} onClose={handleClose} maxWidth="lg">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle style={{ color: Colors.TextElevated, backgroundColor: Colors.BackgroundDrawer }}>
          Edit Facilitator Profile
        </DialogTitle>
        <DialogContent style={{ width: '900px', backgroundColor: Colors.BackgroundMain }}>
          <Stack direction="column" spacing={2}>
            <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
              {/*Facilitator Name*/}
              <Controller
                name="userId"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    id="userId"
                    defaultValue={defaultUser}
                    onChange={(
                      event,
                      newInputValue: {
                        id: string;
                        name: string;
                        company: string;
                      }
                    ) => {
                      field.onChange(newInputValue);
                      setUserId(newInputValue?.id);
                    }}
                    options={users.sort((a, b) => -b.company?.localeCompare(a.company))}
                    groupBy={(option) => option.company}
                    getOptionLabel={(option: { id: string; name: string; company: string }) =>
                      `${option.name} ${` - ${option.company}`}`
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          shrink: true
                        }}
                        sx={{ width: '400px', color: Colors.TextElevated }}
                        label="Facilitator Name"
                        variant="outlined"
                        color="success"
                      />
                    )}
                  />
                )}
              />
              {/*Status*/}
              <Box sx={{ mt: 8 }}>
                <FormControl>
                  <InputLabel shrink>Status</InputLabel>
                  <Controller
                    name="status"
                    control={control}
                    defaultValue={fileObject?.status}
                    render={({ field }) => (
                      <Select
                        {...field}
                        value={status}
                        label="Status"
                        onChange={(event) => setStatus(event?.target?.value as FileObjectStatus)}
                        sx={{ width: '150px' }}
                      >
                        <MenuItem value={FileObjectStatus.Active}>{FileObjectStatus.Active}</MenuItem>
                        <MenuItem value={FileObjectStatus.Inactive}>{FileObjectStatus.Inactive}</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </Box>
            </Stack>

            {/*Description BIO*/}
            <Box>
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    inputRef={inputRef} // Attach ref for cursor manipulation
                    sx={{ width: '100%', pr: 2 }}
                    label="Description / Bio"
                    color="success"
                    multiline
                    rows={10}
                    placeholder="enter bio / description"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    variant="outlined"
                    helperText="To split description into paragraphs just add breaks where needed using the button below:"
                  />
                )}
              />
              <Button sx={{ mt: 2, mb: 3, backgroundColor: Colors.TextElevatedLighter, color: 'white' }} onClick={insertBreak} variant="contained" color="success">
                Add Paragraph Break
              </Button>
            </Box>

            {/*Facilitator photo*/}
            {!azureUrl && (
              <>
                <FormLabel>Upload Facilitator Profile Photo </FormLabel>
                <InputFileUpload buttonName="Upload Photo" onFileSelect={handleSelectedFile} />
              </>
            )}

            <>
              <Card sx={{ width: '30%', height: 'autox' }}>
                <CardContent>
                  <Typography sx={{ color: Colors.TextElevated }}>Facilitator Photo</Typography>
                </CardContent>
                <CardMedia sx={{ height: '100%' }} component="img" image={azureUrl ? azureUrl : noPhotoUrl} />
              </Card>
            </>

            {azureUrl && (
              <>
                <FormLabel>To upload a different Facilitator Photo delete the existing one first</FormLabel>
                <Button
                  variant="outlined"
                  color="error"
                  sx={{ height: '40px', width: '230px' }}
                  onClick={deleteProfilePhoto}
                >
                  Delete Facilitator Photo
                </Button>
              </>
            )}
          </Stack>
        </DialogContent>

        <DialogActions style={{ backgroundColor: Colors.BackgroundDrawer, paddingTop: '20px' }}>
          <>
            <Button
              variant="contained"
              color="primary"
              sx={{ backgroundColor: 'grey', mr: 2, mb: 2 }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{ backgroundColor: Colors.ButtonGreen, mr: 2, mb: 2 }}
              type="submit"
            >
              Save
            </Button>
          </>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditFacilitator;