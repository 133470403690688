import api from '../../services/api';

export enum SurveyActionTypes {
  FETCH_CLIENT_SURVEYS = 'FETCH_CLIENT_SURVEYS'
}

export function fetchClientSurveys(clientId) {
  return function (dispatch) {
    return api.get(`/surveys/${clientId}`).then(({ data }) => {
      dispatch({
        type: SurveyActionTypes.FETCH_CLIENT_SURVEYS,
        payload: data
      });
    });
  };
}

// selectors
export const getSurveysByClient = (state) => state.surveys.clientSurveys;