import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid, { GridSpacing } from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { CardContent } from '@material-ui/core';
import ModifiedAreaChart from '../charts/ModifiedAreaChart';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCurrentClients, getClients } from '../../../redux/actions/clients';
import SessionsCalendarDashboard from './SessionsCalendarDashboard';
import { Link } from 'react-router-dom';
import { getUsers } from '../../../redux/actions/users';
import { ClientStatus, Colors, GroupStatus, UserStatus } from '../../../enums/enums';
import { fetchAllCoachingSessions, getCoachingSessions } from '../../../redux/actions/coachingSessions';
import NewUsersListDashBoard from './NewUsersListDashboard';
import { fetchCurrentGroups, getGroups } from '../../../redux/actions/groups';
import { Typography } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    paper: {
      height: 140,
      width: 220
    },
    control: {
      padding: theme.spacing(2)
    }
  })
);

export default function Dashboard() {
  const [spacing, setSpacing] = React.useState<GridSpacing>(4);
  const classes = useStyles();
  const dispatch = useDispatch();

  const clients = useSelector(getClients);
  const activeClients = clients?.filter((c) => c.status === ClientStatus.Active);
  const users = useSelector(getUsers);
  const activeUsers = users?.filter((u) => u.status === UserStatus.Active);
  const groups = useSelector(getGroups);
  const activeGroups = groups?.filter((g) => g.status === GroupStatus.Active);
  const coachingSessions = useSelector(getCoachingSessions);
  const upcomingSessions = coachingSessions?.filter((s) => new Date(s.date) > new Date());
  const passedSessions = coachingSessions?.filter((s) => new Date(s.date) < new Date());

  React.useEffect(() => {
    dispatch(fetchCurrentClients());
    dispatch(fetchCurrentGroups());
    dispatch(fetchAllCoachingSessions());
  }, [dispatch]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSpacing(Number((event.target as HTMLInputElement).value) as GridSpacing);
  };

  const stats = [
    { title: 'Active Clients', count: activeClients?.length, subTitle: 'Total Clients', subCount: clients?.length },
    { title: 'Active Users', count: activeUsers?.length, subTitle: 'Total Users', subCount: users?.length },
    { title: 'Active Cohorts', count: activeGroups?.length, subTitle: 'Total Cohorts', subCount: groups?.length },
    {
      title: 'Upcoming Sessions',
      count: upcomingSessions?.length,
      subTitle: 'Passed Sessions',
      subCount: passedSessions?.length
    }
  ];

  const usersToApprove = users?.filter((u) => u.status === UserStatus.New);
  const charts = [
    { title: 'This week', chart: <SessionsCalendarDashboard />, to: 'coaching-sessions-calendar' },
    {
      title:
        usersToApprove?.length === 1
          ? `${usersToApprove?.length} new User waiting for approval`
          : usersToApprove?.length > 1
          ? `${usersToApprove?.length} new Users waiting for approval`
          : 'All new users approved, good job!',
      chart: <NewUsersListDashBoard />,
      static: true
    }
  ];

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12} style={{ backgroundColor: Colors.TextElevated, height: '350px', padding: '15px' }}>
        <ModifiedAreaChart
          height="280px"
          option={{
            series: [
              {
                data: [34, 45, 31, 45, 31, 43, 26, 43, 31, 45, 33, 40],
                type: 'line'
              }
            ],
            xAxis: {
              data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
            }
          }}
        ></ModifiedAreaChart>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={spacing} style={{ marginTop: '-50px' }}>
          {stats?.map((value, index) =>
            index <= 4 ? (
              <Grid key={index} item>
                <Card className={classes.paper} elevation={6} style={{ width: '200px' }}>
                  <CardContent>
                    <Typography color="textSecondary" align="center">
                      {value?.title}
                    </Typography>
                    <Typography gutterBottom variant="h3" align="center">
                      {value?.count}
                    </Typography>

                    <Typography color="textSecondary" align="center">
                      {value?.subTitle} : <b>{value?.subCount}</b>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ) : (
              <></>
            )
          )}
        </Grid>
        <Grid container justifyContent="center" spacing={spacing} style={{ marginBottom: 20 }}>
          {stats?.map((value, index) =>
            index > 4 ? (
              <Grid key={index} item>
                <Card className={classes.paper} elevation={6} style={{ width: '200px' }}>
                  <CardContent>
                    <Typography color="textSecondary" align="center">
                      {value?.title}
                    </Typography>
                    <Typography gutterBottom variant="h3" align="center">
                      {value?.count}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ) : (
              <></>
            )
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={spacing}>
          {charts?.map((value, index) => (
            <Grid key={index} item>
              {!value.static && (
                <Link to={value.to} style={{ textDecoration: 'none' }}>
                  <Card style={{ width: '460px', height: '400px', padding: '0' }} elevation={6}>
                    <CardContent style={{ padding: '0' }}>
                      <Typography style={{ background: Colors.TextElevated, color: '#fefefe' }} align="center">
                        {value?.title}
                      </Typography>
                      <Typography gutterBottom align="center">
                        {value?.chart}
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              )}
              {value.static && (
                <Card style={{ width: '490px', height: '400px', padding: '0' }} elevation={6}>
                  <CardContent style={{ padding: '0' }}>
                    <Typography style={{ background: Colors.TextElevated, color: 'white' }} align="center">
                      {value?.title}
                    </Typography>
                    <Typography gutterBottom align="center">
                      {value?.chart}
                    </Typography>
                  </CardContent>
                </Card>
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}