import { AnyAction } from 'redux';
import { ContentActionTypes } from '../actions/content';

const initialState = {
  files: [],
  learningTools: [],
  facilitators: []
};

export function ContentReducer(state = initialState, action: AnyAction) {
  if (!state) {
    return initialState;
  }

  switch (action.type) {
    case ContentActionTypes.FETCH:
      return {
        ...state,
        files: action.payload
      };

    case ContentActionTypes.FETCH_LEARNING_TOOLS:
      return {
        ...state,
        learningTools: action.payload
      };

    case ContentActionTypes.FETCH_FACILITATORS:
      return {
        ...state,
        facilitators: action.payload
      };

    case ContentActionTypes.DELETE:
      console.log('FILES REDUCER ', state, action.payload);
      const idOfDeletedFile = action.payload;
      return {
        ...state,
        files: state.files.filter((file) => file.id !== idOfDeletedFile)
      };

    case ContentActionTypes.DELETE_LEARNING_TOOL:
      console.log('FILES REDUCER ', state, action.payload);
      const deletedLearningTools = action.payload;
      return {
        ...state,
        learningTools: state.learningTools.filter((file) => file.id !== deletedLearningTools)
      };

    case ContentActionTypes.DELETE_FACILITATOR:
      console.log('FILES REDUCER ', state, action.payload);
      const deletedFacilitator = action.payload;
      return {
        ...state,
        facilitators: state.facilitators.filter((file) => file.id !== deletedFacilitator)
      };

    case ContentActionTypes.UPLOAD_FILE:
      return {
        ...state,
        files: [action.payload, ...state.files]
      };

    case ContentActionTypes.UPDATE_FILE:
      return {
        ...state,
        learningTools: state.learningTools.map((learningTool) => {
          if (learningTool.id === action.payload.id) {
            learningTool.status = action.payload.status || learningTool.status;
            learningTool.title = action.payload.title || learningTool.title;
            learningTool.thumbnailUrl = action.payload.thumbnailUrl || learningTool.thumbnailUrl;
            learningTool.updatedAt = action.payload.updatedAt || learningTool.updatedAt;
            learningTool.clientIds = action.payload.clientIds || learningTool.clientIds;
          }
          return learningTool;
        })
      };

    case ContentActionTypes.UPDATE_FACILITATOR:
      return {
        ...state,
        facilitators: state.facilitators.map((facilitator) => {
          if (facilitator.id === action.payload.id) {
            facilitator.status = action.payload.status || facilitator.status;
            facilitator.title = action.payload.title || facilitator.title;
            facilitator.thumbnailName = action.payload.thumbnailName || facilitator.thumbnailName;
            facilitator.updatedAt = action.payload.updatedAt || facilitator.updatedAt;
            facilitator.azureUrl = action.payload.azureUrl || facilitator.azureUrl;
          }
          return facilitator;
        })
      };

    case ContentActionTypes.UPLOAD_LEARNING_TOOL:
      return {
        ...state,
        learningTools: [action.payload, ...state.learningTools]
      };

    default:
      return state;
  }
}