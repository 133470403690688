import * as React from 'react';
import api from '../../../../services/api';
import { Button, Typography, Chip, Accordion, AccordionSummary, AccordionDetails, Box } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import { useState } from 'react';
import { format, parseISO } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteFacilitator,
  fetchFacilitatorsProfiles,
  fetchLearningTools,
  getFacilitators
} from '../../../../redux/actions/content';
import { ConfirmationDialog } from '../../../dialogs/ConfirmationDialog';
import { Colors } from '../../../../enums/enums';
import { toast } from 'react-toastify';
import UsagesModal from '../UsagesModal';
import EditFacilitator from './EditFacilitator';
import { fetchCurrentUsers, getActiveUsers } from '../../../../redux/actions/users';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { InputFileUpload } from '../../../shared/input-file-upload/InputFileUpload';
import DoneIcon from '@mui/icons-material/Done';
import PreviewFacilitatorProfile from './PreviewFacilitatorProfile';
import {noPhotoUrl} from "../../../../constants/constants";

interface FileUploadResponse {
  id: string;
  filename: string;
  url: string;
  createdAt: string;
}

export default function Facilitator() {
  const dispatch = useDispatch();
  const existingFacilitatorProfiles = useSelector(getFacilitators);
  const users = useSelector(getActiveUsers);
  const [tableData, setTableData] = useState([]);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [selectedFileId, setSelectedFileId] = React.useState(null);
  const [fileObject, setFileObject] = React.useState(null);
  const [openEditFacilitatorTool, setOpenEditFacilitatorTool] = React.useState(false);
  const [openPreviewFacilitator, setOpenPreviewFacilitator] = React.useState(false);
  const [openUsagesModal, setOpenUsagesModal] = React.useState({ open: false, sessionList: [], filename: '' });
  const [facilitator, setFacilitator] = useState(null);

  React.useEffect(() => {
    dispatch(fetchFacilitatorsProfiles());
    dispatch(fetchCurrentUsers());
  }, [dispatch]);

  React.useEffect(() => {
    setTableData(
      existingFacilitatorProfiles?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
    );
  }, [existingFacilitatorProfiles]);

  const handleSelectedFile = (files: FileList): void => {
    if (files?.length) {
      const file = files.item(0);
      console.log('File selected', file);

      const formData = new FormData();
      formData.append('file', file);

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
      api.post<FileUploadResponse>(`/content/facilitator`, formData, config).then(({ data }) => {
        dispatch(fetchFacilitatorsProfiles());
        console.log('API response on facilitator photo upload: ', data);
        toast.success('Successfully uploaded facilitator photo');
      });
    }
  };

  const handleDelete = () => {
    dispatch(deleteFacilitator(selectedFileId));
    setConfirmationDialogOpen(false);
  };

  const handleClose = () => {
    setOpenEditFacilitatorTool(false);
    setOpenPreviewFacilitator(false);
    dispatch(fetchLearningTools());
  };

  const columns = [
    {
      name: 'id',
      options: {
        display: false
      }
    },
    {
      name: 'thumbnailName',
      label: 'Name',
      options: {
        customBodyRender: (v, x) => {
          const user = users?.find((u) => u.id === v);
          return (
            <>
              <Typography fontSize="large" sx={{ minWidth: '200px', color: Colors.TextElevated, fontWeight: 'bold' }}>
                {user?.name}
              </Typography>
              <Typography sx={{ color: 'grey' }} fontSize="medium">
                {user?.title}
              </Typography>
            </>
          );
        }
      }
    },
    {
      name: 'title',
      label: 'Description',
      options: {
        customBodyRender: (v, x) => {
          return (
            <Accordion sx={{ m: 'auto' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography>Facilitator BIO</Typography>
                {v && <DoneIcon sx={{ color: Colors.ButtonGreen, ml: 2 }} />}
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{v || ''}</Typography>
              </AccordionDetails>
            </Accordion>
          );
        }
      }
    },
    {
      name: 'azureUrl',
      label: 'Photo',
      options: {
        customBodyRender: (v, x) => (
          <img
            src={v || noPhotoUrl}
            style={{ width: '150px', height: 'auto' }}
          />
        )
      }
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        customBodyRender: (v, x) => (
          <Chip sx={{ backgroundColor: v === 'ACTIVE' ? Colors.Active : Colors.Inactive }} label={v} />
        )
      }
    },
    {
      name: 'createdAt',
      label: 'Created',
      options: {
        sort: true,
        customBodyRender: (value) => {
          const parsedData = parseISO(value);
          return <Typography>{format(parsedData, 'MMM dd, yyyy').toLocaleString()}</Typography>;
        }
      }
    },
    {
      name: 'updatedAt',
      label: 'Updated',
      options: {
        sort: true,
        customBodyRender: (value) => {
          const parsedData = parseISO(value);
          return <Typography>{format(parsedData, 'MMM dd, yyyy').toLocaleString()}</Typography>;
        }
      }
    },
    {
      name: 'id',
      label: ' ',
      options: {
        customBodyRender: (value, x) => {
          const file = existingFacilitatorProfiles?.find((file) => file.id === value);
          return (
            <Button
              variant="contained"
              color="success"
              sx={{ backgroundColor: Colors.TextElevatedLighter }}
              onClick={() => {
                setOpenPreviewFacilitator(true);
                setFileObject(file);
                setFacilitator(users?.find((u) => u?.id === x?.rowData[1]));
              }}
            >
              Preview
            </Button>
          );
        }
      }
    },
    {
      name: 'id',
      label: ' ',
      options: {
        customBodyRender: (value, x) => {
          const file = existingFacilitatorProfiles?.find((file) => file.id === value);
          return (
            <Button
              variant="contained"
              color="success"
              sx={{ backgroundColor: Colors.TextElevated }}
              onClick={() => {
                setOpenEditFacilitatorTool(true);
                setFileObject(file);
              }}
            >
              Edit
            </Button>
          );
        }
      }
    },
    {
      name: 'id',
      label: ' ',
      options: {
        customBodyRender: (value, x) => {
          const handleClick = () => {
            setSelectedFileId(value);
            setConfirmationDialogOpen(true);
          };

          return (
            <Button variant="outlined" color="error" onClick={handleClick}>
              Delete
            </Button>
          );
        }
      }
    }
  ];

  const options = {
    filter: true,
    editable: true,
    filterType: 'dropdown',
    selectableRows: false
  };

  return (
    <>
      <Typography sx={{ mt: 5, mb: 4, ml: 4 }}>
        Create Facilitator Profiles for cohort leaders here to display to participants of their cohorts.
        <Typography sx={{mt: 2}}>
          Start by uploading a <b>Profile Picture</b> for each Facilitator, then click <b>Edit</b> to add additional
          details.
        </Typography>
      </Typography>
      <Box sx={{ my: 5, ml: 3 }}>
        <InputFileUpload buttonName="Upload Picture" onFileSelect={handleSelectedFile} />
      </Box>

      <MUIDataTable
        title={<h2 style={{ color: Colors.Text }}>Facilitator Profiles</h2>}
        editable={true}
        data={tableData}
        columns={columns}
        options={options}
      />
      <ConfirmationDialog
        isOpen={confirmationDialogOpen}
        title="Delete Facilitator Profile?"
        message={
          <>
            <Typography sx={{ mb: 2, fontSize: '1.1rem' }}>
              Are you sure you want to permanently delete this Facilitator's profile?
            </Typography>
            <Typography sx={{ mb: 3 }}>
              Once deleted it won't be accessible to participants anymore and can't be restored, only recreated.
            </Typography>
            <Typography sx={{ mb: 5 }}>
              This action will not affect the database user data of the Facilitator.
            </Typography>
          </>
        }
        onConfirmBtn={handleDelete}
        onCancelBtn={() => setConfirmationDialogOpen(false)}
      />
      <UsagesModal
        data={openUsagesModal}
        onClose={() => setOpenUsagesModal({ open: false, sessionList: [], filename: '' })}
      />
      <EditFacilitator users={users} open={openEditFacilitatorTool} fileObject={fileObject} onClose={handleClose} />
      <PreviewFacilitatorProfile
        open={openPreviewFacilitator}
        fileObject={fileObject}
        facilitator={facilitator}
        handleClose={handleClose}
      />
    </>
  );
}