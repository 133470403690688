import * as React from 'react';
import api from '../../../services/api';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  styled,
  Typography
} from '@mui/material';
import Divider from '@mui/material/Divider';
import { useRef, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { noteEditorApiKey, notesEditorConfig, STAGE_MAPPING, stagesColors } from '../../../constants/constants';
import { Editor } from '@tinymce/tinymce-react';
import { toast } from 'react-toastify';
import { ConfigKeyType } from './config.enum';
import Instructions from './Instructions';
import {SessionStage} from "../mentorship/coaching-sessions/coaching.enums";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(0)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  },
  '& #customized-dialog-title': {
    fontSize: '1.1rem'
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

interface ConfigResponse {
  id: ConfigKeyType;
  value: string;
}

export default function SessionNotes() {
  const [stage, setStage] = useState<SessionStage>(null);
  const [openNotesTemplateEditor, setOpenNotesTemplateEditor] = useState<boolean>(false);
  const [notesTemplateValue, setNotesTemplateValue] = useState<string>(null);
  const notesEditorRef = useRef(null);
  const handleNotesTemplateClose = () => {
    setStage(null);
    setNotesTemplateValue(null);
    setOpenNotesTemplateEditor(false);
  };

  function handleNotesTemplateOpen(stageVal: SessionStage) {
    api.get<ConfigResponse>(`/config/${STAGE_MAPPING[stageVal]}`).then(({ data }) => {
      setStage(stageVal);
      setOpenNotesTemplateEditor(true);
      setNotesTemplateValue(data.value);
    });
  }

  const handleNotesTemplateSave = () => {
    if (notesEditorRef.current) {
      const value = notesEditorRef.current.getContent({ format: 'raw' });
      const payload = { id: STAGE_MAPPING[stage], value };

      api.patch<ConfigResponse>(`/config`, payload).then(({ data }) => {
        toast.success(`Successfully updated ${stage} template`);
        handleNotesTemplateClose();
      });
    }
  };

  return (
    <>
      <Box sx={{ p: 3, pb: 3 }}>
        <Typography sx={{ mb: 3, color: '#343434' }}>
          <h3>Edit Session Notes Templates:</h3>
        </Typography>
        <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: stagesColors['Meet And Greet'],
              ':hover': {
                bgcolor: '#265731'
              }
            }}
            onClick={() => handleNotesTemplateOpen(SessionStage.MeetAndGreet)}
          >
            First Sessions
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: stagesColors['Recurring Session'],
              ':hover': {
                bgcolor: '#6e377e'
              }
            }}
            onClick={() => handleNotesTemplateOpen(SessionStage.Recurring)}
          >
            Recurring Sessions
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: stagesColors['Final Session'],
              ':hover': {
                bgcolor: '#913b3b'
              }
            }}
            onClick={() => handleNotesTemplateOpen(SessionStage.Final)}
          >
            Final Sessions
          </Button>
        </Stack>
      </Box>

      <BootstrapDialog
        maxWidth="md"
        aria-labelledby="customized-dialog-title"
        hidden={!openNotesTemplateEditor}
        open={true}
        onClose={handleNotesTemplateClose}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleNotesTemplateClose}>
          {stage} Template
        </BootstrapDialogTitle>
        <DialogContent sx={{ height: '900px' }} dividers>
          <Editor
            apiKey={noteEditorApiKey}
            value={notesTemplateValue}
            onInit={(evt, editor) => {
              notesEditorRef.current = editor;
            }}
            onEditorChange={(newValue, editor) => {
              setNotesTemplateValue(newValue);
            }}
            init={notesEditorConfig}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" autoFocus onClick={handleNotesTemplateSave}>
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <Instructions />
    </>
  );
}