import { AnyAction } from 'redux';
import { SurveyActionTypes } from '../actions/surveys';

const initialState = {
  clientSurveys: []
};

export function SurveysReducer(state = initialState, action: AnyAction) {
  if (!state) {
    return initialState;
  }

  switch (action.type) {
    case SurveyActionTypes.FETCH_CLIENT_SURVEYS:
      return {
        ...state,
        clientSurveys: action.payload
      };

    default:
      return state;
  }
}